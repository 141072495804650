<template>
  <div class="web_box">
    <van-nav-bar
      title="意见反馈"
      left-arrow
      right-arrow
      @click-left="toBack"
      @click-right="save"
      fixed
      class="header_class"
      :border="false"
      v-if="isiOSSystem"
    >
      <div class="back_icon" slot="left" />
      <div slot="right" class="save_icon">
        保存
      </div>
    </van-nav-bar>

    <div
      class="feed_box"
      :style="isiOSSystem == false ? {'margin-top': '0'} : ''"
    >
      <p>请输入您想给我们的建议：</p>
      <div class="text_area text_area1 bgset">
        <textarea
          rows="3"
          v-model="advice_text"
          placeholder="请输入"
        ></textarea>
      </div>
      <span>0/200</span>
    </div>
    <div class="feed_box">
      <p>症状改善情况：</p>
      <div class="text_area text_area2 bgset">
        <textarea
          rows="3"
          v-model="change_text"
          placeholder="请输入"
        ></textarea>
      </div>
    </div>
    <div class="option_list">
      <p>选项列表</p>
      <ul class="options_box">
        <li
          v-for="item in suggestionTypeList"
          :key="item.id"
          @click="changIt(item)"
        >
          <div class="left_text">
            <p>{{ item.name }}</p>
            <span>{{ item.remark }}</span>
          </div>
          <div
            class="check_icon"
            :class="item.isChange ? 'check_true' : 'check_false'"
          ></div>
          <van-divider class="divider_line" />
        </li>
      </ul>
    </div>
    <div class="next_btn" @click="save()" v-if="isiOSSystem == false">
      <button>保存</button>
    </div>
  </div>
</template>
<script>
import {Toast} from "vant";
import {getSuggestionTypeList, addSuggestions} from "../utils/api";
export default {
  data() {
    return {
      advice_text: "",
      change_text: "",
      suggestionTypeList: "",
      isiOSSystem: false,
    };
  },
  mounted() {
    this.getData();
  },
  created() {
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      this.isiOSSystem = false;
    } else {
      this.isiOSSystem = true;
    }
  },
  methods: {
    getData() {
      getSuggestionTypeList()
        .then((res) => {
          for (let i in res.data.suggestionTypeList) {
            res.data.suggestionTypeList[i].isChange = false;
          }
          this.suggestionTypeList = res.data.suggestionTypeList;
        })
        .catch();
    },
    changIt(item) {
      item.isChange = !item.isChange;
    },
    save() {
      let suggestionTypeNames = [],
        suggestionTypeIds = [];
      for (let i in this.suggestionTypeList) {
        if (this.suggestionTypeList[i].isChange) {
          suggestionTypeNames.push(this.suggestionTypeList[i].name);
          suggestionTypeIds.push(this.suggestionTypeList[i].id);
        }
      }
      addSuggestions({
        uggestionContent: this.advice_text,
        improvement: this.change_text,
        suggestionTypeNames: suggestionTypeNames.join(","),
        suggestionTypeIds: suggestionTypeIds.join(","),
      })
        .then(() => {
          Toast.success("保存成功");
          this.change_text = "";
          this.advice_text = "";
          this.getData();
        })
        .catch();
    },
    // addSuggestions
  },
};
</script>

<style lang="scss" scoped>
.save_icon {
  color: #00c770;
}
.feed_box {
  width: 100%;
  background-color: #12172c;
  margin-top: 108px;
  p {
    padding: 26px 30px 0;
    color: #00ccff;
    font-size: 26px;
  }
  .text_area {
    margin: 19px auto;

    textarea {
      width: 90%;
      height: 222px;
      border: none;
      background: transparent;
      padding: 30px 5%;
      line-height: 40px;
      color: #37708f;
      font-size: 26px;
    }
  }
  .text_area1 {
    background-image: url("../assets/feedback/input_box1.png");
    width: 690px;
    height: 282px;
  }
  .text_area2 {
    background-image: url("../assets/feedback/input_box2.png");
    width: 690px;
    height: 162px;
    textarea {
      height: 102px;
      padding-bottom: 19px;
    }
  }
  span {
    display: block;
    text-align: right;
    padding: 20px 30px 17px;
    color: #00ccff;
    font-size: 26px;
  }
  .bgset {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.feed_box:last-of-type {
  margin-top: 20px;
  padding-bottom: 19px;
}
.option_list {
  width: 100%;
  margin-top: 20px;
  p {
    padding: 26px 30px 0;
    color: #00ccff;
    font-size: 26px;
  }
  .options_box {
    width: 100%;
    background-color: #12172c;
    margin-top: 21px;
    margin-bottom: 70px;
    li {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;
      .left_text {
        text-align: left;
        p {
          font-size: 26px;
          color: #fff;
          margin-bottom: 10px;
          padding: 0;
        }
        span {
          font-size: 24px;
          color: #37708f;
        }
      }
      .check_icon {
        width: 32px;
        height: 32px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .check_icon.check_false {
        background-image: url("../assets/feedback/check_false.png");
      }
      .check_icon.check_true {
        background-image: url("../assets/feedback/check_true.png");
      }
    }
  }
}
.divider_line {
  position: absolute;
  margin: 0 30px !important;
  background-color: #374372;
  //   padding: 0 auto;
  color: #374372;
  width: 85%;
  bottom: 0;
  border-color: #374372;
  flex: none;
}
.next_btn {
  width: 90%;
  height: 88px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 39px auto 71px;
  background-image: url("../assets/register/sub_bg.png");
  button {
    width: 100%;
    height: 100%;
    line-height: 88px;
    color: #00ccff;
    font-size: 30px;
    background-color: transparent;
    text-align: center;
    outline: none;
    border: none;
  }
}
</style>
